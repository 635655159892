<template>
  <v-container
      id="projects"
      fluid
      align="center"
  >

    <base-material-card
        icon="mdi-server"
        inline
        title="Enterprise Project"
    >
      <template v-if="loading">
        <v-progress-linear
            indeterminate
            color="primary"
        ></v-progress-linear>
        <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
      </template>
      <v-row v-else>
        <v-col cols="12">
          <v-row v-if="project">
            <v-col cols="12" class="mt-7 ml-4">
              <v-btn
                  v-if="canAccess({module: 'saas', method: 'get', route: '/project'})"
                  small
                  class="mr-2"
                  color="purple"
                  @click="listProjects"
              >
                <v-icon color="white">mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                  v-if="project.status ==='fail' && canAccess({module: 'saas', method: 'get', route: '/project/retry/:id'})"
                  small
                  class="mr-2"
                  color="warning"
                  @click="retryProject(project);"
              >
                <v-icon color="white"
                        class="mr-1">
                  mdi-play-speed
                </v-icon>
                Retry Create Project
              </v-btn>
              <v-btn
                  v-if="project.status ==='ready' && canAccess({module: 'saas', method: 'put', route: '/project/:id/:mode'})"
                  color="success"
                  small
                  class="mr-2"
                  @click="editProject(project)"
              >
                <v-icon
                    color="white"
                    class="mr-1"
                >
                  mdi-pencil
                </v-icon>
                Edit
              </v-btn>
              <v-btn
                  v-if="project.status !=='pending' && canAccess({module: 'saas', method: 'delete', route: '/project/:id'})"
                  color="error"
                  small
                  class="mr-2"
                  @click="deleteProject(project)"
              >
                <v-icon
                    color="white"
                    class="mr-1"
                >
                  mdi-delete
                </v-icon>
                delete
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs="12" sm="12" md="12" lg="6" xl="6">
              <v-card
                  v-if="project"
                  class="mx-auto"
                  outlined
              >
                <v-list-item three-line disabled>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1">
                      {{ project.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-sm-body-1">{{ project.description }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-card-text v-if="project.status ==='permanentFail'" class="text-sm-body-1">
                  <strong>Failed Project</strong>
                </v-card-text>
                <template v-else-if="project.status !=='ready'">
                  <v-progress-linear
                      indeterminate
                      color="secondary"
                      height="20"
                      striped
                      class="text-sm-body-1"
                  >Preparing Project ...
                  </v-progress-linear>
                  <p class="mt-2 text-sm-body-2 text-center">New enterprise projects take between 7 to 10 minutes to be
                    ready.</p>
                </template>

                <v-card-text v-else class="text-sm-body-1">
                  License Valid till:
<!--                    <strong>{{ new Date(project.key.expDate).toDateString() }}</strong>-->
                  <strong v-if="project.subscription">{{ new Date(project.subscription.expDate).toDateString() }}</strong>
                </v-card-text>
              </v-card>
              <v-alert
                  v-else
                  type="warning"
                  prominent
                  text
                  class="mt-8 pa-8 white--text">
                <v-row
                    align="center"
                >
                  <v-col xs="12" sm="12" md="12" lg="8" xl="8" class="grow">
                    <h3>No Project Found!</h3>
                    <p>It appears that you don't have an Enterprise project yet.</p>
                  </v-col>
                  <v-col xs="12" sm="12" md="12" lg="4" xl="4" class="shrink text-right">
                    <v-btn
                        v-if="!project && canAccess({module: 'saas', method: 'post', route: '/project/:type'})"
                        outlined
                        color="warning"
                        @click="createProject()"
                    >
                      <v-icon class="mr-1">mdi-plus</v-icon>
                      Create
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col xs="12" sm="12" md="12" lg="6" xl="6">
              <v-card
                  class="mx-auto"
                  outlined
              >
                <v-list-item three-line disabled>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1">
                      Need Help ?
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-sm-body-1">
                      Navigate to the official M360 Documentation and
                      learn how to install and run an <strong>M360 Enterprise project</strong>
                      on your hosting platform.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-card-text class="text-right text-sm-body-1">
                  Documentation
                  <a href="https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/1050804233/Installation+Usage"
                     target="_blank">
                    Click Here
                  </a>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <template v-if="project && project.status ==='ready' && project.files && project.files.length > 0">
            <h2 class="mt-5 ml-1">Download Files</h2>
            <v-divider class="mb-5 mt-2"></v-divider>
            <v-simple-table>
              <thead>
              <tr>
                <th class="font-weight-bold text-left" width="10%">
                  Type
                </th>
                <th class="font-weight-bold text-left" width="20%">
                  Name
                </th>
                <th class="font-weight-bold text-left" width="40%">
                  Last Modified
                </th>
                <th class="font-weight-bold text-right" width="10%">
                  Size
                </th>
                <th class="font-weight-bold text-right" width="20%">
                  Download
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(oneFile, i) in project.files"
                  :key="i"
              >
                <td class="text-capitalize text-left" width="10%">
                  <v-chip
                      label
                      :color="oneFile.color"
                  >
                    {{ oneFile.type }}
                  </v-chip>
                </td>
                <td class="text-left" width="20%">{{ oneFile.name }}</td>
                <td class="text-left" width="20%">{{ new Date(oneFile.LastModified).toDateString() }}</td>
                <td class="text-right" width="10%">{{ oneFile.Size | prettyBytes }}</td>
                <td class="text-right" width="30%"
                    v-if="canAccess({module: 'saas', method: 'get', route: '/project/:tCode/download/:file'})">
                  <v-btn @click="downloadFile(project, oneFile)"
                         :key="i"
                         :color="oneFile.color"
                         x-small
                         class="white--text"
                  >
                    <v-icon
                        color="white"
                        x-small
                    >mdi-cloud-download
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </template>
          <template v-if="!project">
            <br/>
            <v-row no-gutters>
              <v-col cols="12">
                <v-hover
                    v-slot="{ hover }"
                    open-delay="50"
                >
                  <v-card
                      elevation="2"
                      class="pt-1"
                      :color="hover ? 'accent': ''"
                  >
                    <v-card-title class="mb-3" :class="{'accent--text': !hover}">
                      <v-icon class="mr-2" :class="{'accent--text': !hover}">mdi-server</v-icon>
                      M360API &reg; Enterprise
                    </v-card-title>
                    <v-card-text :class="(hover) ? 'white--text': ''">
                      For larger enterprises that want an unlimited multi-cloud or on-premise solution.
                    </v-card-text>
                    <v-list
                        id="enterpriseFeatures"
                        :flat="true"
                        :tile="true"
                    >
                      <v-subheader>Features</v-subheader>
                      <v-list-item-group>
                        <v-list-item
                            v-for="(item, i) in enterpriseFeatures"
                            :key="i"
                        >
                          <v-list-item-icon>
                            <v-icon :class="(hover) ? '': 'accent--text'" v-text="item.icon"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title :class="(hover) ? 'white--text': ''"
                                               v-text="item.text"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-hover>
              </v-col>

            </v-row>
          </template>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";

const data = {
  loading: true,
  project: null
};

const methods = {

  async listProjects() {
    const self = this;
    // this.timeNow = new Date().getTime();
    this.loading = true;
    self.project = null;
    this.clearMessages();

    self.getSendData({
      noLoading: true,
      url: '/saas/project',
      method: 'get',
      params: {
        "type": 'enterprise'
      }
    }).then(response => {
      self.loading = false;
      response.forEach((oneRow) => {
        if (oneRow.files) {
          oneRow.files.forEach((oneFile) => {
            if (oneFile.Key) {
              if (oneFile.Key.includes('license')) {
                oneFile.color = 'warning';
              } else if (oneFile.Key.includes('gateway')) {
                oneFile.color = 'success';
                oneFile.type = 'gateway';
              } else if (oneFile.Key.includes('console')) {
                oneFile.color = 'secondary';
                oneFile.type = 'console';
              }
            }
          })
        }
      });
      self.project = response[0];
    }).catch(error => {
      self.loading = false;
      // this.handleErrorResponse(error);
    });

  },

  retryProject(project) {
    const self = this;
    if (confirm('Are you sure you want to retry creating this project?')) {
      self.getSendData({
        url: `/saas/project/retry/${project.id}`,
        method: 'get',
        params: {}
      }).then(response => {
        self.pushMessage({
          type: 'success',
          title: 'Project Create',
          text: `Project ${project.name} has been rescheduled for creation. It May take a few minutes for this project to become ready.`
        });
        setTimeout(() => {
          self.listProjects();
        }, 500);
      }).catch(error => {
        this.handleErrorResponse(error);
      });
    }
  },

  createProject(type) {
    this.$router.push({name: 'createProject', params: {type: 'enterprise'}})
  },

  editProject(project) {
    this.$router.push({name: 'editProject', params: {type: 'enterprise', id: project.id}})
  },

  deleteProject(project) {
    const self = this;
    if (confirm('Are you sure you want to delete this project, all project data will be lost ?')) {
      self.getSendData({
        url: `/saas/project/${project.id}`,
        method: 'delete',
        params: {}
      }).then(response => {
        self.pushMessage({
          type: 'success',
          title: 'Project Deleted',
          text: `Project ${project.name} has been successfully deleted.`
        });
        setTimeout(() => {
          self.listProjects();
        }, 1000);
      }).catch(error => {
        this.handleErrorResponse(error);
      });
    }
  },

  downloadFile(project, oneFile) {
    const self = this;
    self.getSendData({
      url: `/saas/project/${project.code}/download/${oneFile.name}`,
      method: 'get',
      // blob: true,
      params: {}
    }).then(response => {
      window.open(`${process.env.VUE_APP_M360_DOWNLOAD}/project/${project.code}/download/${response}`);
    }).catch(error => {
      this.handleErrorResponse(error);
    });
  }

};

export default {
  name: 'enterpriseProjects',
  methods: methods,
  props: {
    enterpriseFeatures: {
      type: Array
    }
  },
  components: {},

  computed: {},

  mixins: [globalMixins],

  filters: {
    prettyBytes: function (num) {
      // jacked from: https://github.com/sindresorhus/pretty-bytes
      if (typeof num !== 'number' || isNaN(num)) {
        throw new TypeError('Expected a number');
      }

      let exponent;
      let unit;
      let neg = num < 0;
      let units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      if (neg) {
        num = -num;
      }

      if (num < 1) {
        return (neg ? '-' : '') + num + ' B';
      }

      exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
      num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
      unit = units[exponent];

      return (neg ? '-' : '') + num + ' ' + unit;
    }
  },

  async created() {
    await this.listProjects();
  },

  data: () => (data),
}
</script>
